.navbar li .glyphicon {
  margin-right: 10px;
}

.navbar-brand {
  height: 80px;
}

/* Highlighting rules for nav menu items */
.navbar .navbar-nav .active a,
.navbar .navbar-nav .active a:hover,
.navbar .navbar-nav .active a:focus {
  background-image: none;
  background-color: #4189c7;
  color: white;
}

.admin-container {
  padding-top: 30px;
  padding-bottom: 10px;
  width: 100%;
  border-top: 1px solid gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-border-top {
  margin: 10px 16px;
  width: auto;
  border-top: 1px solid gray;
}

.navbar-fixed-top .navbar-collapse {
  max-height: 100% !important;
}

@media (min-width: 768px) {
  /* On large screens, convert the nav menu to a vertical sidebar */
  .navbar {
    height: 100%;
    width: calc(18% - 20px);
    background: #1f2937;
  }

  .navbar {
    border-radius: 0;
    border-width: 0;
    height: 100%;
  }
  .navbar-header {
    float: none;
    background: #5fa5f9;
    color: white;
  }
  .navbar-header p {
    font-size: 15px;
  }
  .navbar-header .navbar-brand {
    color: #fff;
  }
  .navbar .navbar-collapse {
    border-top: 1px solid #444;
    padding: 0;
  }
  .navbar .container-fluid {
    padding: 0;
    margin: 0;
  }
  .navbar .container-fluid .navbar-brand {
    margin: 0;
  }
  .navbar ul {
    float: none;
  }
  .navbar li {
    float: none;
    font-size: 15px;
    margin: 6px;
  }
  .navbar li a {
    padding: 10px 16px;
    border-radius: 4px;
  }
  .navbar a {
    /* If a menu item's text is too long, truncate it */
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
